import { useBusiness } from '@/web/hooks/use-business';
import { add, addDays, format, isSameDay } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import {
  BookingFlowCourses,
  bookingFlowDate,
  bookingFlowLocation,
  bookingFlowRoom,
  bookingFlowSubType,
  bookingFlowType,
} from './atoms';
import { CourseCard, CourseCardSkeleton } from './course-card';
import { sortCourses } from '@/web/utils';
import { FETCH_COURSES, getUserCourses } from '@/web/endpoints';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import { NoResultsFound } from '../no-results-found';
import { useTranslation } from 'react-i18next';
import { formatWithT } from '@/i18n/utils';
import { utcToZonedTime } from 'date-fns-tz';
import { MissingDataAlert } from '@/common/components/missing-data-alert';

interface Params {
  startDate: string;
  endDate: string;
  locations: string;
  rooms?: string;
  types?: string;
  size: number;
}

type Props = {
  courseTypeId?: string | undefined;
};

export const BookingFlow = ({ courseTypeId }: Props) => {
  const { t } = useTranslation();
  const { businessUuid, primaryLocationId, businessLocations, businessCTypes } = useBusiness();

  const [date] = useRecoilState(bookingFlowDate);
  const [type, setType] = useRecoilState(bookingFlowType);
  const [subType, setSubType] = useRecoilState(bookingFlowSubType);
  const [location, setLocation] = useRecoilState(bookingFlowLocation);
  const [room] = useRecoilState(bookingFlowRoom);

  useEffect(() => {
    if (type || courseTypeId) {
      setSubType(null);
    }
  }, [courseTypeId, type, setSubType]);

  useEffect(() => {
    if (courseTypeId) {
      setType(courseTypeId);
    }
  }, [setType, courseTypeId]);

  const selectedType = useMemo(() => {
    return subType || courseTypeId || type;
  }, [subType, courseTypeId, type]);

  const shouldSetPrimaryLocation = useMemo(
    () => !!primaryLocationId && (!location || !businessLocations?.find((loc) => loc.id === location)),
    [businessLocations, location, primaryLocationId],
  );

  const queryParams = useMemo(
    () =>
      shouldSetPrimaryLocation
        ? []
        : ([FETCH_COURSES, businessUuid, format(date, 'yyyy-MM-dd'), selectedType, location, room] as string[]),
    [businessUuid, location, selectedType, date, room, shouldSetPrimaryLocation],
  );

  useEffect(() => {
    if (shouldSetPrimaryLocation && primaryLocationId) {
      setLocation(primaryLocationId);
    }
  }, [shouldSetPrimaryLocation, primaryLocationId, setLocation]);

  const onFetchCoursesQuery = useCallback(async ({ queryKey }: { queryKey: string[] }) => {
    const bUuid = queryKey[1];
    const locationUuid = queryKey[4];
    const room = queryKey[5];
    const typeUuid = queryKey[3];
    const date = queryKey[2];

    if (bUuid && locationUuid) {
      const amsterdamTime = utcToZonedTime(new Date(date), 'Europe/Amsterdam');
      const nextDayInAmsterdam = add(amsterdamTime, { days: 1 });
      const formattedDate = format(nextDayInAmsterdam, 'yyyy-MM-dd');
      const params: Params = {
        startDate: date,
        endDate: formattedDate,
        locations: locationUuid,
        rooms: room,
        size: 50,
      };

      if (typeUuid) {
        params.types = typeUuid;
      }

      const coursesResp = await getUserCourses(bUuid, params);
      const sortedCourses = sortCourses(coursesResp);
      const coursesByUuid = sortedCourses?.length
        ? sortedCourses.reduce((acc, curr) => {
            acc[curr.uniqueUuid] = curr;

            return acc;
          }, {} as BookingFlowCourses)
        : null;

      return coursesByUuid;
    }
  }, []);

  const { isFetching, data: courses } = useQuery(queryParams, onFetchCoursesQuery);

  const iterableCourses = Object.values(courses || {});

  return (
    <div className="flex flex-col">
      <MissingDataAlert />
      {isFetching && (
        <motion.div
          className="flex flex-col flex-1"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {Array.from({ length: 3 }, (_, idx) => (
            <CourseCardSkeleton key={`ccs-${idx}`} />
          ))}
        </motion.div>
      )}
      {!isFetching && !iterableCourses.length && (
        <div className="flex flex-col items-center justify-center h-contentHeight">
          <NoResultsFound label={t('noResults.classes')} />
        </div>
      )}
      {!isFetching && !!iterableCourses.length && (
        <motion.div
          className="flex flex-col flex-1 pb-16"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {iterableCourses.map((course, index) => {
            return (
              <CourseCard
                key={course.uniqueUuid}
                course={course}
                shouldScrollTo={index === 0 || (iterableCourses[index - 1]?.isPast && !course.isPast)}
              />
            );
          })}
        </motion.div>
      )}
    </div>
  );
};

export const BookingFlowNavigation = () => {
  const { businessCTypes } = useBusiness();
  const [date, setDate] = useRecoilState(bookingFlowDate);
  const today = new Date();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const maxDays = useMemo(() => {
    const maxSubscribeDays = Math.max(...(businessCTypes || []).map((courseType) => courseType.options.subscribeDays));

    return Math.min(Math.max(7, maxSubscribeDays), 28) + 1;
  }, [businessCTypes]);

  return (
    <div className="px-4 absolute bottom-full pb-2 w-full z-10">
      <div className="flex justify-between gap-2 relative z-[9]">
        <div className="py-1 flex rounded-lg bg-gray-50 h-[56px] overflow-x-auto px-2" ref={containerRef}>
          <div className="relative flex flex-1 gap-2 h-full">
            {Array.from({ length: maxDays }, (_, index) => {
              const currentDate = addDays(today, index);
              const title = formatWithT(currentDate, 'iii');
              const dayOfMonth = formatWithT(currentDate, 'd');
              const isSelected = isSameDay(date, currentDate);

              return (
                <motion.button
                  key={`day-${index}`}
                  className={clsx(
                    'relative z-10 flex-1 whitespace-nowrap px-2 h-[48px] w-[48px] rounded-md transition-colors uppercase font-medium text-xs duration-500',
                    {
                      'text-primary-contrast-text': isSelected,
                      'text-typo-secondary': !isSelected,
                    },
                  )}
                  onClick={() => setDate(currentDate)}
                >
                  <div className="flex flex-col">
                    <span className="relative z-[2]">{title}</span>
                    <span className="relative z-[2]">{dayOfMonth}</span>
                  </div>
                  {isSelected && (
                    <motion.div
                      className="bg-primary rounded-md left-0 top-0 right-0 bottom-0 absolute z-[1]"
                      layoutId="underline"
                      ref={(ref) => {
                        if (ref) {
                          ref.scrollIntoView();
                        }
                      }}
                      layout
                      transition={{ type: 'spring', bounce: 0.25, duration: 0.5 }}
                    />
                  )}
                </motion.button>
              );
            })}
          </div>
        </div>
      </div>
      <div
        className="absolute bottom-0 left-0 pb-2 h-28 w-full z-[8] select-none pointer-events-none"
        style={{ background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100%)' }}
      />
    </div>
  );
};
