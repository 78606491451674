import { useAuth } from '@/auth/hooks/use-auth';
import { User } from '@/auth/types';
import { PageWrapper } from '@/common/components/page-wrapper';
import { useSnack } from '@/common/hooks/use-snack';
import { GAvatar } from '@/design-system/g-avatar';
import { GLoader } from '@/design-system/g-loader';
import Button from '@/design-system/v3/button';
import { checkInMock, getBusinessCheckinInfo, getQRCode } from '@/web/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { useQuery, useQueryClient } from 'react-query';
import GymlySymbol from '@/gymly-symbol.svg?react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { appState, panelState } from '@/common/atoms';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CheckinFailureReason, CheckinUsageType } from '@/web/types';
import { ExclamationTriangleIcon } from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
import { getProfilePath, INVOICES_PATH, SHOP_PATH } from '@/web/routes';
import { HapticFeedbackType, triggerHapticFeedback } from '@/common/native-bridge/utils';
import { PullToRefresh } from '@/common/components/pull-to-refresh';
import NfcIcon from '@/common/components/icon/icons/v2/nfc.svg?react';

function getFullName(user: User | null): string | null {
  if (!user) return null;

  return [user.firstName, user.insertion, user.lastName].filter(Boolean).join(' ');
}

export const FETCH_BUSINESS_CHECKIN_INFO = 'FETCH_BUSINESS_CHECKIN_INFO';

const SUPPORTED_FAILURE_REASONS = [
  CheckinFailureReason.INVALID_MEMBERSHIP,
  CheckinFailureReason.INVOICE_DUE,
  CheckinFailureReason.NO_COURSE_SUBSCRIPTION,
  CheckinFailureReason.INVALID_TIME_RANGE,
];

const QR_CODE_FETCH = 'QR_CODE_FETCH';

export const QRCodeView = () => {
  const { t } = useTranslation();
  const { successMessage, errorMessage } = useSnack();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { businessUuid, primaryLocationId, businessSettings } = useBusiness();
  const { foreground } = useRecoilValue(appState);
  const setPanel = useSetRecoilState(panelState);

  const {
    data: qrBlob,
    isLoading,
    isFetching,
  } = useQuery([QR_CODE_FETCH, foreground], async () => {
    return getQRCode(businessUuid as string);
  });

  const handleRefresh = useCallback(async () => {
    return queryClient.invalidateQueries([QR_CODE_FETCH]);
  }, [businessUuid, queryClient]);

  useQuery([FETCH_BUSINESS_CHECKIN_INFO, businessUuid], async () => {
    const checkinInfo = await getBusinessCheckinInfo(businessUuid as string);

    const failureReason = checkinInfo.failureReasons.find((reason) => SUPPORTED_FAILURE_REASONS.includes(reason));

    if (!failureReason) {
      return;
    }

    triggerHapticFeedback(HapticFeedbackType.NOTIFICATION_ERROR);
    setPanel({
      component: CheckinWarningModal,
      isOpen: true,
      autoHeight: true,
      props: {
        failureReason,
      },
    });
  });

  useEffect(() => {
    window['ReactNativeWebView']?.postMessage?.(JSON.stringify({ type: 'SET_BRIGHTNESS', payload: 1 }));

    return () => {
      window['ReactNativeWebView']?.postMessage?.(JSON.stringify({ type: 'RESET_BRIGHTNESS' }));
    };
  }, []);

  const content = (
    <>
      <div className="h-contentHeight">
        <div className="flex flex-col h-full items-center">
          <div className="w-full mt-5">
            <PullToRefresh onRefresh={handleRefresh}>
              <div className="rounded-xl w-full overflow-hidden">
                <div className="bg-white pb-4 pt-6 -m-[1px]">
                  <div className="flex justify-center items-center mb-8">
                    <div
                      style={{ width: '134px', height: '17px' }}
                      className="bg-gray-50 shadow-inner rounded-full"
                    ></div>
                  </div>
                  <div className="flex flex-col space-y-3">
                    <div className="flex flex-col items-center space-y-4">
                      <div className="w-[80px] h-[80px] relative">
                        <GAvatar path={user?.profileImagePath} size="2xl" letter={user?.firstName?.charAt(0)} />
                        <div
                          className="flex flex-row items-center gap-[2px] right-0 -bottom-2 z-[1] h-[22px] w-[38px] absolute py-1 px-[6px] bg-black-100 text-white rounded-full shadow-2xl"
                          style={{
                            filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1))',
                          }}
                        >
                          <GymlySymbol />
                          <span className="text-xs leading-[14px] font-median">id</span>
                        </div>
                      </div>
                      <span className="text-lg font-semibold text-black-100">{getFullName(user)}</span>
                    </div>
                  </div>
                  {businessSettings?.checkinUsageType === CheckinUsageType.ALL && (
                    <div className="flex flex-col items-center">
                      {isLoading || isFetching ? (
                        <div className="flex h-[325px] w-[325px] rounded-xl my-4 justify-center items-center border-gray-200 border">
                          <GLoader variant="secondary" />
                        </div>
                      ) : (
                        <GAvatar src={qrBlob} className="w-full max-w-[325px] h-[325px] rounded-none" />
                      )}
                    </div>
                  )}
                  {businessSettings?.checkinUsageType === CheckinUsageType.NFC_ONLY && (
                    <div className="flex flex-col items-center">
                      <div className="flex h-[215px] w-[325px] rounded-xl my-4 justify-center items-center bg-gray-50">
                        <div className="flex flex-col space-y-2 items-center justify-center text-center">
                          <div className="text-primary">
                            <NfcIcon />
                          </div>
                          <div className="font-neutral">{t('useRfidHint')}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </PullToRefresh>
            {businessSettings?.checkinUsageType === CheckinUsageType.ALL && (
              <div className="flex flex-col items-center py-8 justify-center">
                <div className="text-typo-secondary text-sm mx-4 text-center">{t('qrCodeView.message')}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {(window.isStaging || window.isDev) && (
        <Button
          variant="primary"
          onClick={async () => {
            try {
              await checkInMock(businessUuid as string, primaryLocationId as string);
              successMessage('Check in successful', {
                detailedMessage: 'You have successfully checked in to the gym.',
              });
            } catch (e) {
              errorMessage('Check in failed', {
                detailedMessage:
                  (e as Error).message ||
                  'Oops! An unexpected error has occurred. Please try again later or contact support for assistance.',
              });
            }
          }}
        >
          Check in
        </Button>
      )}
    </>
  );
  return <PageWrapper className="bg-gray-50" content={content} />;
};

export const CheckinWarningModal = ({ failureReason }: { failureReason: CheckinFailureReason }) => {
  const { t } = useTranslation();
  const { hasPurchasableMemberships } = useBusiness();
  const navigate = useNavigate();
  const setPanel = useSetRecoilState(panelState);

  return (
    <div className="flex flex-col items-center justify-center text-center text-base space-y-4">
      <ExclamationTriangleIcon className="text-typo-negative w-12 h-12" />
      <div className="flex flex-col space-y-1">
        <span className="font-semibold text-typo-primary">
          {failureReason === CheckinFailureReason.INVALID_MEMBERSHIP && t('checkinWarning.invalidMembership.title')}
          {failureReason === CheckinFailureReason.INVOICE_DUE && t('checkinWarning.invoiceDue.title')}
          {failureReason === CheckinFailureReason.NO_COURSE_SUBSCRIPTION &&
            t('checkinWarning.noCourseSubscription.title')}
          {failureReason === CheckinFailureReason.INVALID_TIME_RANGE && t('checkinWarning.invalidTimeRange.title')}
        </span>
        <span className="text-gray-600">
          {failureReason === CheckinFailureReason.INVALID_MEMBERSHIP && t('checkinWarning.invalidMembership.message')}
          {failureReason === CheckinFailureReason.INVOICE_DUE && t('checkinWarning.invoiceDue.message')}
          {failureReason === CheckinFailureReason.NO_COURSE_SUBSCRIPTION &&
            t('checkinWarning.noCourseSubscription.message')}
          {failureReason === CheckinFailureReason.INVALID_TIME_RANGE && t('checkinWarning.invalidTimeRange.message')}
        </span>
      </div>
      <div className="w-full">
        {failureReason === CheckinFailureReason.NO_COURSE_SUBSCRIPTION && (
          <Button
            variant="primary"
            onClick={() => {
              setPanel(null);
              navigate('/');
            }}
          >
            {t('checkinWarning.action.subscribe')}
          </Button>
        )}
        {failureReason === CheckinFailureReason.INVOICE_DUE && (
          <Button
            variant="primary"
            onClick={() => {
              setPanel(null);
              navigate(getProfilePath(INVOICES_PATH));
            }}
          >
            {t('checkinWarning.action.pay')}
          </Button>
        )}
        {hasPurchasableMemberships &&
          (failureReason === CheckinFailureReason.INVALID_MEMBERSHIP ||
            failureReason === CheckinFailureReason.INVALID_TIME_RANGE) && (
            <Button
              variant="primary"
              onClick={() => {
                setPanel(null);
                navigate(SHOP_PATH);
              }}
            >
              {t('checkinWarning.action.viewMemberships')}
            </Button>
          )}
      </div>
    </div>
  );
};
