import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CourseTypeHomeCard, HomeCardType, LinkHomeCard } from '@/web/modules/home-card/types';
import { isApp } from '@/common/utils';
import { useSetRecoilState } from 'recoil';
import { panelState } from '@/common/atoms';
import { IframePanel } from '@/common/components/IframePanel';

type Props = {
  card: CourseTypeHomeCard | LinkHomeCard;
};

export const HomeCard = ({ card }: Props) => {
  const navigate = useNavigate();
  const setPanel = useSetRecoilState(panelState);
  const { label, imageUrl } = card;

  const handleClick = useCallback(() => {
    switch (card.type) {
      case HomeCardType.COURSE_TYPE:
      case HomeCardType.ACTIVITY:
        navigate(`/course-type/${card.courseType.id}`);
        break;
      case HomeCardType.LINK:
        if (card.link.newWindow) {
          if (isApp()) {
            window.open(card.link.url);
          } else {
            window.open(card.link.url, '_blank', 'noopener,noreferrer');
          }
        } else {
          setPanel({
            title: card.label,
            isOpen: true,
            component: IframePanel,
            childrenClassName: 'px-0 pb-0',
            draggable: false,
            props: {
              url: card.link.url,
            },
          });
        }
    }
  }, [navigate, card]);

  return (
    <button className="flex flex-col justify-center items-center space-y-1" onClick={handleClick}>
      <div className="flex items-center justify-center w-full h-[120px] rounded-xl overflow-hidden bg-gray-50">
        {imageUrl && <img src={imageUrl} alt={label} className="object-cover w-full h-full" />}
      </div>
      <div className="text-center text-xs font-semibold">{label}</div>
    </button>
  );
};
