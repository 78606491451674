import { GCard } from '@/design-system/v3/g-card';
import { useBusiness } from '@/web/hooks/use-business';
import { Title } from '@/design-system/v3/title';
import { useContext, useEffect, useMemo, useState } from 'react';
import { GRadio } from '@/design-system/v3/g-radio';
import Text from '@/design-system/v3/text';
import { OnboardingStepsLayout } from '../onboarding-steps-layout';
import { OnboardingFooter } from '../onboarding-footer';
import { OnboardingTransition } from '../onboarding-transition';
import { useTranslation } from 'react-i18next';
import { OnboardingFormContext } from '@/auth/views/onboarding';
import { MembershipPaymentType, PaymentSchedule } from '@/web/types';
import { getLocationPaymentSchedule } from '@/auth/endpoints';
import { SafeHtml } from '@/common/components/safe-html';

interface Props {
  onPreviousStep: () => void;
  onNextStep: (id: string) => void;
}

export const OnboardingMembership = ({ onNextStep, onPreviousStep }: Props) => {
  const { t } = useTranslation();
  const { businessUuid, businessMemberships } = useBusiness();
  const { primaryLocationId } = useContext(OnboardingFormContext);
  const [offset, setOffset] = useState(5);
  const [selectedMembership, setSelectedMembership] = useState<string | null>(null);
  const [schedule, setSchedule] = useState<PaymentSchedule | null>();

  const publicBusinessMemberships = useMemo(() => {
    return (businessMemberships ?? []).filter((membership) => membership.public === undefined || membership.public);
  }, [businessMemberships]);

  useEffect(() => {
    if (businessUuid) {
      getLocationPaymentSchedule(businessUuid, primaryLocationId).then((response) => {
        setSchedule(response.schedule);
      });
    }
  }, [businessUuid, primaryLocationId]);

  return (
    <OnboardingStepsLayout title={t('onboarding.membership.title')}>
      <OnboardingTransition>
        <div>
          {publicBusinessMemberships.slice(0, offset).map((membership) => {
            return (
              <GCard
                as="label"
                key={membership.id}
                className="first:rounded-t-md last:border-b last:rounded-b-md border-b-0 rounded-none cursor-pointer active:bg-gray-50"
              >
                <GCard.Content>
                  <div className="flex justify-between items-center">
                    <GRadio
                      as="div"
                      checked={membership.id === selectedMembership}
                      onChange={(ev) => setSelectedMembership(ev.target.value)}
                      value={membership.id}
                      label={<span className="text-sm font-medium">{membership.name}</span>}
                      subtitle={
                        <>
                          {membership.description !== null && (
                            <div className="text-typo-primary">
                              <SafeHtml html={membership.description.replace(/\n/g, '<br />')} />
                            </div>
                          )}
                          {membership.paymentType === MembershipPaymentType.PERIODIC && schedule && (
                            <>
                              {schedule.type === 'WEEKLY' && schedule.weekInterval && (
                                <span className="text-sm">
                                  {t('everyWeek', {
                                    count: schedule.weekInterval,
                                  })}
                                </span>
                              )}

                              {schedule.type === 'MONTHLY' && schedule.dayOfMonth && (
                                <span className="text-sm">
                                  {t('everyMonthOn', {
                                    count: schedule.dayOfMonth,
                                  })}
                                </span>
                              )}
                            </>
                          )}
                          {membership.paymentType === MembershipPaymentType.YEARLY && (
                            <Text variant="small">{t('everyYear')}</Text>
                          )}
                          {membership.paymentType === MembershipPaymentType.ONCE && (
                            <div>
                              <Text variant="small">{t('onceOff')}</Text>
                              {membership.entries && (
                                <Text variant="small" className="ml-1">
                                  -{' '}
                                  {t('nrOfEntry', {
                                    count: membership.entries,
                                  })}
                                </Text>
                              )}
                              {membership.expireDays && (
                                <Text variant="small" className="ml-1">
                                  -{' '}
                                  {t('nrOfDay', {
                                    count: membership.expireDays,
                                  })}
                                </Text>
                              )}
                            </div>
                          )}
                        </>
                      }
                    />
                    <Text variant="small" bold>
                      {membership.amountFormatted}
                    </Text>
                  </div>
                </GCard.Content>
              </GCard>
            );
          })}
          {(publicBusinessMemberships?.length || 0) > offset && (
            <GCard className="first:rounded-t-md last:border-b last:rounded-b-md border-b-0 rounded-none cursor-pointer active:bg-gray-50 p-0">
              <GCard.Content>
                <button className="w-full p-3" onClick={() => setOffset(offset + 5)}>
                  {t('showMore')}
                </button>
              </GCard.Content>
            </GCard>
          )}
        </div>
        <OnboardingFooter
          onPreviousStep={onPreviousStep}
          disabled={!selectedMembership}
          onNextStep={() => {
            if (selectedMembership) onNextStep(selectedMembership);
          }}
        />
      </OnboardingTransition>
    </OnboardingStepsLayout>
  );
};
