import { ONBOARDING_BUSINESS_ID } from '@/common/constants';
import { getFromStorage } from '@/common/utils';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';
import { ReactNode } from 'react';
import { hasUserCompletedProfile } from '@/auth/views/onboarding/utils';
import { MEDIA_PRIVACY_PATH } from '@/auth/routes';

export const RequireAuth = ({
  children,
  isOnboarding,
  isMediaPrivacy,
}: {
  children: ReactNode;
  isOnboarding?: boolean;
  isMediaPrivacy?: boolean;
}) => {
  const { isLoggedIn, user, isUserOnboarded } = useAuth();
  const location = useLocation();

  if (!isLoggedIn) {
    const fromPath = location.pathname.includes('logout') ? null : location;
    return <Navigate to="/login" state={{ fromPath }} replace />;
  }

  if (!user) {
    return null;
  }

  if (!hasUserCompletedProfile(user) && !isMediaPrivacy) {
    return <Navigate to={MEDIA_PRIVACY_PATH} replace />;
  }

  if (isUserOnboarded && location.pathname.includes('/onboarding') && !location.pathname.includes('/business')) {
    return <Navigate to="/" replace />;
  }

  if (!isUserOnboarded && !isOnboarding) {
    const businessUuid = getFromStorage(ONBOARDING_BUSINESS_ID);
    const to = businessUuid ? `/onboarding/${businessUuid}` : '/onboarding';
    return <Navigate to={to} replace />;
  }

  return <>{children}</>;
};
