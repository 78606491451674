import { useBusiness } from '@/web/hooks/use-business';
import { CourseCard, CourseCardSkeleton } from '@/common/components/booking-flow/course-card';
import { addWeeks, format, isSameDay, isToday, isTomorrow, subWeeks } from 'date-fns';
import { useQuery, useQueryClient } from 'react-query';
import { CuteSeparator } from '../course-detail';
import { sortCourses } from '@/web/utils';
import { FETCH_TEACHER_CLASSES, getUserCourses } from '@/web/endpoints';
import { NoResultsFound } from '@/common/components/no-results-found';
import { useTranslation } from 'react-i18next';
import { formatWithT } from '@/i18n/utils';
import { useCallback } from 'react';
import { PullToRefresh } from '@/common/components/pull-to-refresh';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { useAuth } from '@/auth/hooks/use-auth';
import { BackToButton } from '../extra-menu';
import { PROFILE_PATH } from '@/web/routes';

export const useUpcomingTeacherCourses = () => {
  const { isTeacher, isStaffMember } = useAuth();
  const shouldFetch = isTeacher || isStaffMember;
  const { businessUuid, businessUser } = useBusiness();
  const { data: courses, isFetching } = useQuery(
    [FETCH_TEACHER_CLASSES, businessUuid, businessUser?.id, shouldFetch],
    async () => {
      if (businessUuid && businessUser?.id && shouldFetch) {
        const today = new Date();
        const startDate = format(subWeeks(today, 1), 'yyyy-MM-dd');
        const endDate = format(addWeeks(today, 2), 'yyyy-MM-dd');
        const params = {
          teachers: businessUser?.id,
          startDate,
          endDate,
          fresh: true,
        };
        const courses = await getUserCourses(businessUuid, params);

        return sortCourses(courses);
      }

      return null;
    },
    {
      staleTime: 100000,
      enabled: shouldFetch,
    },
  );

  return { courses, isFetching, nrOfCourses: courses?.length || 0 };
};

export const TeacherCoursesView = () => {
  const { courses, isFetching } = useUpcomingTeacherCourses();
  const { t } = useTranslation();
  const qc = useQueryClient();

  const indexOfFirstNonPast = courses?.findIndex((course) => course.startAtDate >= new Date());

  const getContent = useCallback(() => {
    return isFetching ? (
      <>
        {Array.from({ length: 6 })
          .fill(null)
          .map((_, idx) => (
            <CourseCardSkeleton key={`ccs-${idx}`} />
          ))}
      </>
    ) : !(courses && courses.length) ? (
      <div className="flex flex-row justify-center items-center p-20 h-contentHeight">
        <NoResultsFound label={t('noResults.classes')} />
      </div>
    ) : (
      <div className="flex flex-col">
        {courses.map((course, index) => {
          const isTodayCheck = isToday(course.startAtDate);
          const isTomorrowCheck = isTomorrow(course.startAtDate);
          const dateFormatted = isTodayCheck
            ? t('bookingFlow.navigation.today')
            : isTomorrowCheck
              ? t('bookingFlow.navigation.tomorrow')
              : formatWithT(course.startAtDate, 'MMM dd');
          const prevCourse = courses[index - 1];

          let cuteSeparator: null | JSX.Element = <CuteSeparator label={dateFormatted} />;

          if (prevCourse) {
            if (isSameDay(course.startAtDate, prevCourse.startAtDate)) {
              cuteSeparator = null;
            }
          }

          return (
            <div className="flex flex-col" key={course.uniqueUuid}>
              {cuteSeparator}
              <CourseCard
                course={course}
                withLocation
                withTeachers={false}
                shouldScrollTo={index === indexOfFirstNonPast}
              />
            </div>
          );
        })}
      </div>
    );
  }, [courses, isFetching, t]);

  return (
    <PageWrapper
      header={
        <PageHeader title={t('profileMenuView.yourClasses.title')} leftAction={<BackToButton path={PROFILE_PATH} />} />
      }
      contentClasses="px-0"
      content={
        <PullToRefresh
          className="flex-1"
          onRefresh={() => {
            return qc.invalidateQueries(FETCH_TEACHER_CLASSES);
          }}
        >
          {getContent()}
        </PullToRefresh>
      }
    />
  );
};
