import { gaxios } from '@/api';
import { Paginator } from '@/common/types';
import { WebshopItem, WebshopItemStatus, WebshopItemWithId } from './types';

export const GET_BUSINESS_WEBSHOP_ITEMS = 'GET_BUSINESS_WEBSHOP_ITEMS';
export const getBusinessWebshopItems = async (
  businessUuid: string,
  params: { status?: WebshopItemStatus; size: number; page: number; categories?: string },
): Promise<Paginator<WebshopItemWithId>> => {
  if (params.categories === '') delete params.categories;

  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/webshop/items`,
    params,
  });

  return resp.data;
};

export const GET_BUSINESS_WEBSHOP_CATEGORIES = 'GET_BUSINESS_WEBSHOP_CATEGORIES';
export const getBusinessWebshopCategories = async (
  businessUuid: string,
  params: { size: number; page: number },
): Promise<Paginator<{ name: string; id: string }>> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/businesses/${businessUuid}/webshop/categories`,
    params,
  });

  return resp.data;
};

export const addBusinessWebshopItem = async (businessUuid: string, data: WebshopItem): Promise<WebshopItemWithId> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/businesses/${businessUuid}/webshop/items`,
    data,
  });

  return resp.data;
};

export const updateBusinessWebshopItemClick = async (
  businessUuid: string,
  itemId: string,
): Promise<WebshopItemWithId> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/businesses/${businessUuid}/webshop/items/${itemId}/clicks`,
  });

  return resp.data;
};

export const deleteBusinessWebshopItem = async (businessUuid: string, itemId: string): Promise<void> => {
  const resp = await gaxios({
    method: 'DELETE',
    url: `/businesses/${businessUuid}/webshop/items/${itemId}`,
  });

  return resp.data;
};

export const addBusinessWebshopItemImage = async (
  businessUuid: string,
  itemId: string,
  file: File,
): Promise<WebshopItemWithId> => {
  const formData = new FormData();
  formData.append('file', file);

  const resp = await gaxios({
    method: 'POST',
    url: `/businesses/${businessUuid}/webshop/items/${itemId}/image`,
    data: formData,
  });

  return resp.data;
};
