import { gaxios } from '@/api';

export const registerDeviceFCMToken = async (token: string): Promise<void> => {
  const resp = await gaxios({
    method: 'POST',
    url: '/user/device-token',
    data: { token },
  });

  return resp.data;
};
