import SplitScreenImageLayout from '@/common/layouts/SplitScreenImageLayout';
import React, { useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import BringAFriendClassInvitation from './steps/BringAFriendClassInvitation';
import BringAFriendInvitationHeaderSkeleton from '../../components/BringAFriendInvitationHeader/BringAFriendInvitationHeaderSkeleton';
import { GPageLoader } from '@/design-system/v3/g-page-loader';
import { useQuery } from 'react-query';
import { BRING_A_FRIEND_INVITATIONS, getInvitationDetail } from '@/features/bring-a-friend/api/BringAFriendApi';
import { useBusiness } from '@/web/hooks/use-business';
import { useSetRecoilState } from 'recoil';
import { selectedLocaleState } from '@/i18n/atoms';
import { languageToLocale } from '@/i18n/utils';

const BringAFriendInvitation = () => {
  const { setBusinessUuid } = useBusiness();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { invitationId } = useParams();
  const setSelectedLocale = useSetRecoilState(selectedLocaleState);

  const token = searchParams.get('token');

  const { data, isFetching } = useQuery([BRING_A_FRIEND_INVITATIONS, invitationId], async () => {
    if (!invitationId || !token) {
      return;
    }

    const invitationDetails = await getInvitationDetail(invitationId, token);
    setBusinessUuid(invitationDetails.course.businessId);
    setSelectedLocale(languageToLocale(invitationDetails.language));

    return invitationDetails;
  });

  const currentStep = useMemo(() => {
    if (!data || isFetching || !token) {
      return <BringAFriendInvitationHeaderSkeleton />;
    }

    return <BringAFriendClassInvitation invitation={data} token={token} />;
  }, [data, token, isFetching]);

  if (!invitationId || !token) {
    return navigate('/');
  }

  if (isFetching) {
    return <GPageLoader />;
  }

  return (
    <SplitScreenImageLayout imagePath={data?.course.types[0].imagePath} locationId={data?.course.locationId}>
      <div className="py-8 md:py-16">{currentStep}</div>
    </SplitScreenImageLayout>
  );
};

export default BringAFriendInvitation;
