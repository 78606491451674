import { CourseDetailCard } from '@/common/components/booking-flow/course-detail-card';
import { GLoader } from '@/design-system/g-loader';
import { ReactNode, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { FETCH_COURSE_DETAIL_QUERY, FETCH_COURSE_MEMBERS_QUERY, getCourse, getCourseMembers } from '@/web/endpoints';
import { PageHeader, PageWrapper } from '@/common/components/page-wrapper';
import { BackToButton } from '../extra-menu';

import { useAuth } from '@/auth/hooks/use-auth';
import { CourseOptionsMenu } from '@/web/views/course-detail/course-options-menu';
import { useTranslation } from 'react-i18next';
import { CourseActions } from '@/common/components/booking-flow/course-actions';
import Text from '@/design-system/v3/text';
import { twMerge } from 'tailwind-merge';
import { SkeletonLoading } from '@/common/components/booking-flow/course-card';
import { CourseDetailWithHelpers, CourseMemberStatus, CourseWithHelpers } from '@/web/types';
import { useUpcomingReservations } from '@/web/views/reservations';
import { useWaitlist } from '@/web/views/waitlist';
import { PullToRefresh } from '@/common/components/pull-to-refresh';
import { useBusiness } from '@/web/hooks/use-business';

export const CourseDetailView = () => {
  const { t } = useTranslation();
  const { isTeacher, isStaffMember } = useAuth();
  const [optionsOpen, setOptionsOpen] = useState(false);

  const { course } = useCourseDetail();
  const { members } = useCourseMembers();

  return (
    <PageWrapper
      header={
        <PageHeader
          title={course ? course.types[0].name : <SkeletonLoading className="h-4 w-[120px]" />}
          leftAction={<BackToButton path="/" />}
          rightAction={
            <>
              {course && (isTeacher || isStaffMember) && (
                <button onClick={() => setOptionsOpen(true)}>{t('edit')}</button>
              )}
            </>
          }
        />
      }
      contentClasses="px-0"
      footerClasses="px-4 py-4 mb-0 shadow-footerCourseDetail"
      content={
        <>
          <CourseDetailAboutView />
          {course && <CourseOptionsMenu course={course} open={optionsOpen} onClose={() => setOptionsOpen(false)} />}
        </>
      }
      footer={course ? <CourseActions course={course} members={members || []} /> : <SkeletonLoading className="h-12" />}
    />
  );
};

export const CuteSeparator = ({ label, className }: { label: ReactNode; className?: string }) => {
  return (
    <Text className={twMerge('p-4 text-typo-secondary border-b border-b-borders-secondary', className)} variant="small">
      {label}
    </Text>
  );
};

export const useCourseStatusInfo = (course: CourseWithHelpers | CourseDetailWithHelpers | null | undefined) => {
  const { businessUser } = useBusiness();
  const upcomingReservations = useUpcomingReservations();
  const waitlist = useWaitlist();

  const isMember = !!(
    course &&
    upcomingReservations.courses &&
    upcomingReservations.courses.find((r) => {
      return (
        MEMBER_ACTIVE_STATUSES.includes(r.status) &&
        r.courseDetails.id === course.id &&
        r.courseDetails.startAt === course.startAt
      );
    })
  );
  const isTeacher = businessUser && (course?.teachers ?? []).map((t) => t.id).includes(businessUser.id);
  const spots = course ? course.capacity - course.memberCount : 0;
  const isInWaitlist = !!(
    course &&
    waitlist.courses &&
    waitlist.courses.find((r) => r.id === course.id && r.startAt === course.startAt)
  );
  const canJoin = spots > 0 && !isMember && !isInWaitlist;
  const canSubscribe = !spots && !isMember && !isInWaitlist;
  const canUnsubscribe = !spots && !isMember && isInWaitlist;
  const canCancel = isMember && !isInWaitlist;

  return {
    isMember,
    isInWaitlist,
    isTeacher,
    canJoin,
    canSubscribe,
    canUnsubscribe,
    canCancel,
    shouldDisableJoinButton: !!course?.accessRestrictions?.length,
    spots,
  };
};

export const useCourseDetail = () => {
  const { uuid, startAt } = useParams();
  const { data: course, isFetching } = useQuery(
    [FETCH_COURSE_DETAIL_QUERY, uuid, startAt],
    async () => {
      if (uuid && startAt) {
        return getCourse(uuid, startAt);
      }

      return null;
    },
    {
      staleTime: Infinity,
    },
  );

  return { course, isFetching, ...useCourseStatusInfo(course) };
};

export const MEMBER_ACTIVE_STATUSES = [
  CourseMemberStatus.ATTENDED,
  CourseMemberStatus.ACTIVE,
  CourseMemberStatus.NO_SHOW,
];

export const useCourseMembers = () => {
  const { uuid, startAt } = useParams();
  const { data: members, isFetching } = useQuery(
    [FETCH_COURSE_MEMBERS_QUERY, uuid, startAt],
    async () => {
      if (uuid && startAt) {
        return getCourseMembers(uuid, startAt);
      }

      return null;
    },
    {
      staleTime: 180000, // 3 minutes
    },
  );

  return useMemo(() => {
    const sortedMembers = (members ?? []).sort((a, b) => {
      const isAActive = MEMBER_ACTIVE_STATUSES.includes(a.status);
      const isBActive = MEMBER_ACTIVE_STATUSES.includes(b.status);

      if (isAActive && !isBActive) return -1;
      if (!isAActive && isBActive) return 1;

      if (a.status === CourseMemberStatus.WAITING && b.status !== CourseMemberStatus.WAITING) return -1;
      if (a.status !== CourseMemberStatus.WAITING && b.status === CourseMemberStatus.WAITING) return 1;

      if (a.fullName && b.fullName) {
        return a.fullName.localeCompare(b.fullName);
      }

      return 0;
    });

    return {
      members: sortedMembers.filter((m) => MEMBER_ACTIVE_STATUSES.includes(m.status)),
      allMembers: sortedMembers,
      isFetching,
    };
  }, [members, isFetching]);
};

export const CourseDetailAboutView = () => {
  const { t } = useTranslation();
  const { uuid, startAt } = useParams();
  const queryClient = useQueryClient();
  const { isFetching, course, isMember } = useCourseDetail();

  return isFetching ? (
    <div className="flex items-center justify-center h-contentHeight">
      <GLoader variant="secondary" />
    </div>
  ) : !course ? (
    <div className="flex items-center justify-center h-contentHeight">{t('courseDetails.notFound')}</div>
  ) : (
    <PullToRefresh
      className="flex-1"
      onRefresh={() => {
        return queryClient.invalidateQueries([FETCH_COURSE_DETAIL_QUERY, uuid, startAt]);
      }}
    >
      <div className="flex flex-col">
        <CourseDetailCard course={course} isMember={isMember} />
      </div>
    </PullToRefresh>
  );
};
